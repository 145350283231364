import React, { useState } from "react";
import InstagramIcon from '../../component/Icons/InstagramIcon';
import PageLayout from '../../component/PageLayout';
import { useToast } from "../../services/Toster";
import api from '../../services/api';
import './style.css';
import EmailIcon from "../../component/Icons/EmailIcon";
import IconWhatsapp from "../../component/Icons/IconWhatsapp";
import IconPhone from "../../component/Icons/IconPhone";

export default function ContactUs() {
  const notify = useToast();
  const initialFormData = {
    Contact_Email_ID: "",
    Contact_Mob_No: "",
    Contact_Name: "",
    Contact_Remarks: "",
    Subject: "",
  };
  const [formData, setFormData] = useState(initialFormData); // Array to hold the added items

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.Contact_Name) {
      notify("error", "Contact Name is required!");
      return;
    }

    if (!formData.Contact_Mob_No) {
      notify("error", "Contact Number is required!");
      return;
    }

    if (!formData.Contact_Email_ID) {
      notify("error", "Contact email id is required!");
      return;
    }
    if (!formData.Subject) {
      notify("error", "Subject is required!");
      return;
    }
    if (!formData.Contact_Remarks) {
      notify("error", "Remarks is required!");
      return;
    }

    try {
      const response = await api.post("/create_contact_us", formData, {
        headers: {
          "Content-Type": "application/json", 
        },
      });

      if (response.data) {
        notify("success", "Thank you for reaching out! Your message has been received, and our team will get back to you shortly.");
        setFormData(initialFormData);
      } else {
        notify("error", response.data.message);
      }
    } catch (error) {
      console.error("Error failed:", error);
      notify("error", "Some exception has occured.");
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <PageLayout>   
         <section className="section ptinner preorder contactpage">
         <div className="titleone text-center">
            <h4>Contact Us For Inquiry</h4>
          </div>
          <div className="bigscreen pagewidth" style={{margin:0}}>
           <div className="container">               
            <div className='contactmain'>
             <div className="bookform">
               <div className="bookform-left">
                 <form onSubmit={handleSubmit}>
                     <div className="form_input">
                       <label htmlFor="Contact_Name">
                         Your Name <small>*</small>
                       </label>
                       <input
                       className="inputbox"
                         type="text"
                         placeholder="Your Name"
                         required
                         value={formData.Contact_Name}
                         name="Contact_Name"
                         onChange={handleInputChange}
                       />
                     </div>
                     <div className="form_input">
                       <label htmlFor="Contact_Mob_No">
                         Your Contact <small>*</small>
                       </label>
                       <input
                       className="inputbox"
                         type="text"
                         placeholder="Your Contact"
                         value={formData.Contact_Mob_No}
                         name="Contact_Mob_No"
                         onChange={handleInputChange}
                         required
                       />
                     </div>
                     <div className="form_input">
                       <label htmlFor="Contact_Email_ID">
                         Your Email <small>*</small>
                       </label>
                       <input
                       className="inputbox"
                         type="email"
                         placeholder="Your Email"
                         value={formData.Contact_Email_ID}
                         name="Contact_Email_ID"
                         onChange={handleInputChange}
                         required
                       />
                     </div>

                        <div className="form_input">
                       <label htmlFor="Subject">
                         Subject <small>*</small>
                       </label>
                       <input
                       className="inputbox"
                         type="text"
                         placeholder="Your Subject"
                         value={formData.Subject}
                         name="Subject"
                         onChange={handleInputChange}
                         required
                       />
                     </div>                     
   
                     <div className="textarea">
                       <label htmlFor="Contact_Remarks">Your Message</label>
                       <textarea
                         rows={4}      
                         placeholder=" Your Message Here.."
                         value={formData.Contact_Remarks}
                         name="Contact_Remarks"
                         onChange={handleInputChange}
                       ></textarea>
                     </div>
   
                     <div className="form-btn">
                       <button type="submit" className="btn submitForm">
                         Send
                       </button>
                     </div>
                 </form>
               </div>            
             </div>
             <div className='information'>
                <div className='title'><h2>Information</h2></div>              

                <div className='contactinfo iconsFollow'> 
                <a  title='Email' className='leftspace btn emailicon' href='mailto:spiceonwheels@web.de'><EmailIcon/>  spiceonwheels@web.de</a>                                     
                <a title='instagram' className='leftspace btn instagram' href="https://www.instagram.com/spiceonwheels.de" target="_blank"><InstagramIcon/> Find us on instagram</a>                   
                <a title='Whatsapp' className='leftspace btn whatsapp' href="https://wa.me/+4915175272858?text=Welcome%20to%20Spice%20on%20Wheel" target="_blank"><IconWhatsapp/> Chat with us</a>                   
                <a title='contact number' className='leftspace btn contact' href="tel:+4915175272858"><IconPhone/> Call us</a>                   
                </div>

             </div>
             </div>           
           </div>
           </div>
         </section>
       </PageLayout>
  )
}
