import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useToast } from "../../services/Toster";
import api from "../../services/api";

export default function ResetPassword() {
  const { token } = useParams(); // Get token from URL
  const notify = useToast();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!token) {
      notify("error", "Invalid or expired reset link.");
      navigate("/admin");
    }
  }, [token, navigate, notify]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    const { newPassword, confirmPassword } = formData;

    if (!newPassword || !confirmPassword) {
      notify("error", "Please fill all fields.");
      return;
    }

    if (newPassword.length < 5) {
        notify("error", "Password must be at least 5 characters long.");
        return;
      }
      
    if (newPassword !== confirmPassword) {
      notify("error", "Passwords do not match.");
      return;
    }

    try {
      setIsLoading(true);

      const response = await api.post("/reset-password", {
        token, // ✅ Send token from URL
        newPassword,
      });

      if (response.status === 200) {
        notify("success", "Password reset successful.");
        navigate("/admin");
      }
    } catch (error) {
      notify("error", error?.response?.data?.message || "Failed to reset password.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="loginpage">
      <form className="loginform" onSubmit={handleResetPassword} autoComplete="off">
        <div className="loginlogo">
          {/* ✅ Use public path or import for cleaner handling */}
          <img src="/images/logo.svg" alt="Spice on Wheel" />
          <h5>Reset Password</h5>
        </div>
        <div className="formfield">
          <input
            type="password"
            name="newPassword"
            placeholder="New Password"
            value={formData.newPassword}
            onChange={handleChange}
            required
            autoComplete="new-password"
          />
        </div>
        <div className="formfield">
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            autoComplete="new-password"
          />
        </div>
        <button
          type="submit"
          disabled={
            isLoading ||
            !formData.newPassword ||
            !formData.confirmPassword ||
            formData.newPassword !== formData.confirmPassword
          }
        >
          {isLoading ? "Resetting..." : "Reset Password"}
        </button>
      </form>
    </section>
  );
}
