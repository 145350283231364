import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import Lottie from "lottie-react";
import { BrowserRouter } from "react-router-dom";
import "../src/assest/css/admin.css";
import "../src/assest/css/style.css";
import NavRouting from "./component/NavRouting";
import { ToastProvider } from "./services/Toster";
import animationData from "./webloading.json";
import Lenis from 'lenis'


function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      $(".websiteloader").fadeOut(500, () => setLoading(false));
      
      setTimeout(() => {
        setLoading(false);
      }, 500);

    };

    fetchData();
  }, []);
  
  useEffect(() => {
    const handleScrollToTop = (event) => {
      const target = event.target.closest("a");
      if (target) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };
    document.addEventListener("click", handleScrollToTop);
    return () => {
      document.removeEventListener("click", handleScrollToTop);
    };
  }, []);

  useEffect(() => {    
    const lenis = new Lenis({
      autoRaf: true,
    });
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
    return () => {
      lenis.destroy();
    };
  }, []);

  return (
      <BrowserRouter
        future={{
          v7_relativeSplatPath: true,
          v7_startTransition: true,
        }}
      >
        <ToastProvider>
          <NavRouting loading={loading} />
          {loading && (
            <div className="websiteloader">
              <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
              />
            </div>
          )}
        </ToastProvider>
      </BrowserRouter>
  );
}

export default App;
