import React, { useEffect, useState } from "react";
import AdminHeader from "../../../../component/AdminComponent/AdminHeader";
import IconClose from "../../../../component/Icons/IconClose";
import api from "../../../../services/api";
import { useToast } from "../../../../services/Toster";
import IconArrowLeft from "../../../../component/Icons/IconArrowLeft";
import PaginationWidget from "../../../../component/AdminComponent/PaginationWidget";
import LeafIcon from "../../../../component/Icons/LeafIcon";
import RoundCircalIcon from "../../../../component/Icons/RoundCircalIcon";
import { MultiSelect } from "react-multi-select-component";
import IconSearch from "../../../../component/Icons/IconSearch";

const options = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
  { label: "E", value: "E" },
  { label: "F", value: "F" },
  { label: "G", value: "G" },
  { label: "H", value: "H" },
  { label: "I", value: "I" },
  { label: "J", value: "J" },
  { label: "K", value: "K" },
  { label: "L", value: "L" },
  { label: "M", value: "M" },
  { label: "N", value: "N" },
  { label: "O", value: "O" },
  { label: "P", value: "P" },
  { label: "Q", value: "Q" },
  { label: "R", value: "R" },
  { label: "S", value: "S" },
  { label: "T", value: "T" },
  { label: "U", value: "U" },
  { label: "V", value: "V" },
  { label: "W", value: "W" },
  { label: "X", value: "X" },
  { label: "Y", value: "Y" },
  { label: "Z", value: "Z" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
];

export default function AddDishes() {
  const initialFormData = {
    Category: "",
    Dish_Name: "",    
    Addons:"",
    Description: "",
    Allergydetails: "",
    Vegan: false,
    // Quantity: "",
    Price: "",
    Gluten_free: false,
    ChefsSpecial: false,
  };
  const notify = useToast();
  const [menuItems, setMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [categories, setCategories] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [checkedItems, setCheckedItems] = useState({}); 
  const [itemsPerPage, setItemsPerPage] = useState(20); 
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  // Adjust items per page based on window width
  const handleResize = () => {
    if (window.innerWidth <= 1450) {
      setItemsPerPage(10); 
    } else {
      setItemsPerPage(20);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await api.post("/list_RegularCategories");      
      setCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  
  useEffect(() => {
    fetchCategories();
  }, []);

  // dish list data api
  const fetchData = async () => {
    try {
      const response = await api.post(
        "/list_regular_dishes",
        {},
        {
          headers: { "Content-Type": "application/json" },
        }
      ); 
      // console.log("response", response.data.data);     
      const reversedItems = response.data.data.reverse();

      const checkedState = {};
      reversedItems.forEach((item) => {
        checkedState[item.ID] = item.status; // Set initial checkbox state
      });      

      setCheckedItems(checkedState);       
      setMenuItems(reversedItems);
      setFilteredItems(reversedItems); 
      setTotalPages(Math.ceil(reversedItems.length / itemsPerPage));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchTerm, menuItems]);

  const paginateItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredItems.slice(startIndex, endIndex);
  };

  // input data get handleInputChange
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if(e.target.name == 'Vegan' ||e.target.name == 'Gluten_free'||e.target.name == 'ChefsSpecial'){
      value = e.target.checked;    
    }
    setFormData({ ...formData, [name]: value });
  };

  // dish name add api
  const handleAddItem = async () => {
    // Validate required fields
    if (!formData.Category) {
      notify("error", "Select Category Name is required.");
      return;
    }
    if (!formData.Dish_Name) {
      notify("error", "Enter Dish Name is required.");
      return;
    }
    if (!formData.Price) {
      notify("error", "Enter Price is required.");
      return;
    }

    if (!formData.Allergydetails) {
      notify("error", "Select Allergy details is required.");
      return;
    }
    
    const isDuplicate = menuItems.some(
      (item) => item.Category === formData.Category && item.Dish_Name === formData.Dish_Name
    );

    if (isDuplicate) {
      notify("error", "Dish with the same name already exists in this category.");
      return;
    }

    setIsLoading(true);
    try {
      const _newDish = {
        ...formData,
        Date_Time_Stamp: new Date(),
      };
      const response = await api.post("/create_regular_dishes", _newDish, {
        headers: { "Content-Type": "application/json" },
      });
      // console.log("response.status", response);
      if (response.status === 201 && response.data) {
        fetchData();
        setFormData(initialFormData);
        notify("success", "Dish added successfully");
      } else {
        // console.error("Error: API did not return expected data.", response.data);
        notify(
          "error", "Failed to save dish. Please try again."
        );
      }
    } catch (error) {
      //console.error("Error fetching data:", error);
      notify("error", "Error fetching data:", error);
    }finally {
      setIsLoading(false);
    }
  };

  // Remove Dish card
  const handleRemoveItem = async (index, categoryId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this dish?"
    );
    if (!confirmDelete) return; // Exit if user cancels
    try {
      const response = await api.post(
        "/delete_regular_dishes",
        { id: categoryId },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200) {
        setMenuItems((prevItems) => prevItems.filter((_, i) => i !== index));
        notify("success", "Dish delete successfully");
      } else {
        //console.error("Failed to delete dish");
        notify("error", "Failed to delete dish");
      }
    } catch (error) {
      //console.error("Error deleting dish:", error);
      notify("error", "Error deleting dish:", error);
    }
  };
  const handleSearch = () => {
    if (!searchTerm) {
      setFilteredItems(menuItems);
      setTotalPages(Math.ceil(menuItems.length / itemsPerPage));
    } else {
      const filtered = menuItems.filter(
        (item) =>
          item.Dish_Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.Category.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredItems(filtered);
      setTotalPages(Math.ceil(filtered.length / itemsPerPage));
    }
    setCurrentPage(1); // Reset to first page when searching
  };

  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

useEffect(() => {
  setCheckedItems({});
}, []);

// Update handleCheckboxChange to remove localStorage updates
const handleCheckboxChange = async (id) => {
  const newStatus = !checkedItems[id]; // Toggle status
  
  try {
    // Send API request to update the status
    const response = await api.post("/update_card_status", 
      { id: id, status: newStatus ? 1 : 0 }, 
      { headers: { "Content-Type": "application/json" } }
    );
    if (response.status === 200) {
      setCheckedItems((prevCheckedItems) => ({
        ...prevCheckedItems,
        [id]: newStatus,
      }));

      setMenuItems((prevItems) =>
        prevItems.map((item) =>
          item.ID === id ? { ...item, status: newStatus } : item
        )
      );
    } else {
      console.error("Failed to update status");
    }
  } catch (error) {
    console.error("Error updating status:", error);
  }
};


  return (
    <section className="dashboardpage">
      <AdminHeader pageTitle="Regular Menu Dish" />
      <div className="pageLeftspace">
        <div className="form-fields">
          <form>
            <div className="grid-container mainmenudish">
              <div className="grid-item custom-select">
                <label>Select Category <sup>*</sup></label>
                <IconArrowLeft/>
                <select
                  value={formData.Category}
                  name="Category"
                  className="form-control"
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Category Name</option>
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.Category}>
                      {cat.Category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid-item">
                <label>Dish Name <sup>*</sup></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Dish Name"
                  name="Dish_Name"
                  value={formData.Dish_Name}
                  onChange={handleInputChange}
                  required
                />
              </div>
            
              <div className="grid-item">
                <label>Extra Items</label>
                <input
                  name="Addons"
                  placeholder="Enter Extra Items"
                  className="form-control"   
                  value={formData.Addons} 
                  onChange={handleInputChange}              
                />
              </div>
              <div className="grid-item">
                <label>Dish Description</label>
                <input
                  name="Description"
                  placeholder="Enter Description"
                  className="form-control" 
                  value={formData.Description} 
                  onChange={handleInputChange}                    
                />
              </div>
              {/* <div className="grid-item priceinput">
                <label>Dish Quantity</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Quantity"
                  name="Quantity"
                  value={formData.Quantity}
                  onChange={handleInputChange}
                />
              </div> */}             
              <div className="grid-item">
                <label>Allergy details <sup>*</sup></label>               
                  <MultiSelect
                      options={options}
                      value={options.filter((option) =>
                        formData.Allergydetails?.split(",").includes(option.value)
                      )}
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions.map(option => option.value);
                        setFormData({ ...formData, Allergydetails: selectedValues.join(",") });
                      }}
                      labelledBy="Select"
                      //isOpen={true}
                    />
              </div>
              <div className="grid-item priceinput">
                <label>Dish Price <sup>*</sup></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Dish Price"
                  name="Price"
                  value={formData.Price}
                  onChange={handleInputChange}
                />
              </div>
              <div className="grid-item innerselectitems">
              <div className="iconsinner">
                <label>Vegan</label>
                <span>
                <div className={`icon-checkbox ${formData.Vegan ? "checked" : ""}`}></div>
                <input
                  type="checkbox"
                  placeholder="Vegan"
                  name="Vegan"
                  checked={formData.Vegan}
                  onChange={handleInputChange}
                />
                  <figcaption onClick={() => handleInputChange({ target: { name: 'Vegan', checked: !formData.Vegan } })} >  
                      <LeafIcon />
                </figcaption>
                
                </span>
              </div>
              <div className="iconsinner">
                <label>Gluten free</label>
                <span>
                <div className={`icon-checkbox ${formData.Gluten_free ? "checked" : ""}`}></div>
                  <input
                  type="checkbox"
                  placeholder="Gluten free"
                  name="Gluten_free"
                  checked={formData.Gluten_free}
                  onChange={handleInputChange}
                />
                <figcaption onClick={() => handleInputChange({ target: { name: 'Gluten_free', checked: !formData.Gluten_free } })} >  
                  <RoundCircalIcon />
                </figcaption>
                </span>
              </div>
              <div className="iconsinner">
                <label>Chef's Special</label>                
                <span>
                <div className={`icon-checkbox ${formData.ChefsSpecial ? "checked" : ""}`}></div>
                  <input
                  type="checkbox"
                  placeholder="Chef's Special"
                  name="ChefsSpecial"
                  checked={formData.ChefsSpecial}
                  onChange={handleInputChange}
                />
                <figcaption onClick={() => handleInputChange({ target: { name: 'ChefsSpecial', checked: !formData.ChefsSpecial } })}>
                <img src="../../../../images/cap.svg" alt="cap"/>
                </figcaption>
                </span>
              </div>
              </div>
            
               <button 
                className="btn" 
                type="button" 
                onClick={handleAddItem} 
                disabled={isLoading} 
              >
                {isLoading ? (
                 "Save Item..."
                ) : (
                  "Save Item"
                )}
              </button>              
            </div>
          </form>

          <div className="search-bar">
          <input
          type="text"
          className="form-control"
          placeholder="Search Dish name or Category..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <IconSearch/>
          </div>
          <div className="menuItems ragulardish">
          {paginateItems().map((item, index) => (
              <div className={`dishname ${checkedItems[item.ID] ? "active" : "inactive"}`} key={index}>

                <IconClose onClick={() => handleRemoveItem(index, item.ID)} />
                <input
                  type="checkbox"
                  name="checkbox" 
                  id={`category-${item.ID}`}
                  checked={!!checkedItems[item.ID]} 
                  onChange={() => handleCheckboxChange(item.ID)}
                />
                <h4>
                  <label htmlFor={`category-${item.ID}`} className="icon-checkbox"></label>
                  {item.Category} 
                  </h4>
                <div className="description">
                  <div className="ragulardishname">                    
                    <h3 htmlFor={item.dish}>
                      {item.Dish_Name && ` ${item.Dish_Name}`} 
                    </h3>                      
                    <span>{item.Addons ? `(${item.Addons})` : ""}</span>
                    <p>{item.Description && ` ${item.Description}`}</p>
                  </div>
                  
                  {/* {item.Quantity ? (
                    <div className="textariabox">Qty: <strong>{item.Quantity}</strong></div>
                  ) : (
                    ""
                  )} */}
                  {item.Price ? (
                    <div className="textariabox">Dish Price: <strong>{item.Price}</strong></div>                   
                  ) : (
                    ""
                  )}
                  <div className="cardicons">
                  {item.Vegan && <LeafIcon />}
                  {item.Gluten_free && <RoundCircalIcon />}
                  {item.ChefsSpecial && <img src="../../../../images/cap.svg" alt="cap" />}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {menuItems.length > itemsPerPage && (
          <PaginationWidget
            totalPages={totalPages}
            onPageChanged={handlePageChange}
          />
          )}

        </div>
      </div>
    </section>
  );
}
