import React, { useEffect, useState } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import LocationIcon from "../Icons/LocationIcon";
import IconHom from "../Icons/IconHom";
import IconMenuClose from "../Icons/IconMenuClose";
import api from "../../services/api";

export default function PhoneMenu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");
  const [upcomingLocations, setUpcomingLocations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  
  // ✅ Toggle Menu
  const toggleMenu = () => setMenuOpen(!menuOpen);

  // ✅ Handle Scroll Lock
  useEffect(() => {
    if (menuOpen) {
      document.documentElement.classList.add("hidescroll");
    } else {
      document.documentElement.classList.remove("hidescroll");
    }

    return () => document.documentElement.classList.remove("hidescroll");
  }, [menuOpen]);

  // ✅ Fetch Location Details  
  const fetchLocationDetails = async () => {
    // const currentDay = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(new Date());
    try {
      const response = await api.post(
        "/getLocationCurrent",
        {},
        { headers: { "Content-Type": "application/json" } }
      );      

      if (response.data && response.data.data) {
        // ✅ Normalize to array format
        const locationData = Array.isArray(response.data.data)
          ? response.data.data
          : [response.data.data]; // Convert single object to array

        // ✅ Set current location to the first location
        setCurrentLocation({
          Address: locationData[0]?.Address || "",
          DateTimeFrom: locationData[0]?.Date_Time_From || null, // Use null instead of ""
          DateTimeTo: locationData[0]?.Date_Time_To || null,
          Day_Name: locationData[0]?.Day_Name || ""
        });       
      }
    } catch (error) {
      console.error("Error fetching location details:", error);
    }
    try {
      const responseUpcoming = await api.post(
        "/getLocation_Upcoming",
        // { selDayName: currentDay },
        { },
        { headers: { "Content-Type": "application/json" } }
      );

      console.log("Upcoming Full API response:", responseUpcoming);
      console.log("Upcoming Response data:", responseUpcoming.data);

      if (responseUpcoming.data && responseUpcoming.data.data) {
        // ✅ Normalize to array format
        const locationData = Array.isArray(responseUpcoming.data.data)
          ? responseUpcoming.data.data
          : [responseUpcoming.data.data]; // Convert single object to array

        // ✅ Set current location to the first location
        setUpcomingLocations({
          Address: locationData[0]?.Address || "",
          DateTimeFrom: locationData[0]?.Date_Time_From || null,
          DateTimeTo: locationData[0]?.Date_Time_To || null,
          Day_Name: locationData[0]?.Day_Name || ""
        });
      }


    } catch (error) {
      console.error("Error fetching location details:", error);
    }
  };



  useEffect(() => {
    fetchLocationDetails();
  }, []);

  // ✅ Open Google Maps for Current Location
  const openCurrentLocation = () => {
    if (currentLocation) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          currentLocation.Address
        )}`,
        "_blank"
      );
    } else {
      console.error("Current location not available");
    }
  };

  // ✅ Open Google Maps for Upcoming Location
  const openUpcomingLocation = (address) => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        address
      )}`,
      "_blank"
    );
  };

  return (
    <div className="headermenu">
      {/* ✅ Logo */}
      <div className="pgonelogo">
        <NavLink to="/">
          <img src="../images/logo.svg" alt="Spice on Wheel" />
        </NavLink>
      </div>

      {/* ✅ Right Section */}
      <div className="headerright">
        <strong
          onClick={() => setShowModal(true)}
          style={{ cursor: "pointer" }}
        >
          <LocationIcon />
        </strong>
        <div
          className={`hideshowbar ${menuOpen ? "rotate-top" : ""}`}
          onClick={toggleMenu}
        >
          <span>
            <IconHom />
          </span>
          <label>
            <IconMenuClose />
          </label>
        </div>
      </div>

      <nav className={`desktopmenu ${menuOpen ? "open" : ""}`}>
        <ul>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "active" : "")}
              end
            >
              Home
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/menu"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Menu
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/gallery"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Gallery
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/location"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Location
            </NavLink>
          </li>
          <li className="logo">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "active" : "")}
              end
            >
              <img src="../images/logo.svg" alt="Spice on Wheel" />
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/catering"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Catering
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/pre-order"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Pre-Order
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about-us"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact-us"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </nav>


      {/* ✅ Modal */}
      {showModal && (
        <>
          <div className="modal fade show d-block" tabIndex="-1" role="dialog">
            <div
              className="loactionmodalinphone modal-dialog h-screen flex items-center m-auto max-w-4xl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title font-bold text-[20px]">
                    Locations
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowModal(false)}
                  />
                </div>

                <div className="modal-body text-left">
                  {/* ✅ Current Location */}
                  <h5 className="text-[16px] font-bold">Current Location</h5>
                  {currentLocation ? (
                    <div className="flex items-start flex-col gap-2 mt-3">
                      <ul className="flex flex-col gap-2">
                      <li><strong>Day:</strong> {currentLocation.Day_Name} </li>                        
                        <li>
                          <strong>Date:</strong> {new Date(currentLocation.DateTimeFrom).toLocaleDateString('de-DE', 
                            { day: '2-digit', month: '2-digit', year: 'numeric' })}
                        </li>

                        <li>
                          <strong>Time:</strong> From{" "}
                          {currentLocation.DateTimeFrom.slice(10,16)}                          
                          - To{" "}
                          {currentLocation.DateTimeTo.slice(10,16)}                          
                        </li>

                        <li>
                          <strong>Address:</strong> {currentLocation.Address}
                        </li>
                      </ul>
                      <button
                        type="button"
                        className="btn mt-2"
                        onClick={openCurrentLocation}
                      >
                        Current Location on Maps
                      </button>
                    </div>
                  ) : (
                    <p className="text-muted mt-3">No current location available.</p>
                  )}

                  <hr className="my-4" />
                  {/* ✅ Upcoming Location */}
                  <div className="mt-4">
                    <h5 className="text-[16px] font-bold">
                      Upcoming Location
                    </h5>
                    {upcomingLocations ? (
                      <div className="flex items-start flex-col gap-2 mt-3">
                        <ul className="flex flex-col gap-2">
                        <li><strong>Day:</strong> {upcomingLocations.Day_Name} </li>
                          <li>
                            <strong>Date:</strong>{" "}
                            {/* {upcomingLocations.DateTimeFrom.slice(0,10)} */}
                            {new Date(upcomingLocations.DateTimeFrom).toLocaleDateString('de-DE', 
                            { day: '2-digit', month: '2-digit', year: 'numeric' })}
                          </li>
                          <li>
                            <strong>Time:</strong> From{" "}
                            {upcomingLocations.DateTimeFrom.slice(10,16)}                           
                            - To{" "}
                            {upcomingLocations.DateTimeTo.slice(10,16)}                            
                          </li>

                          <li>
                            <strong>Address:</strong>{" "}
                            {upcomingLocations.Address}
                          </li>
                        </ul>
                        <button
                          type="button"
                          className="btn mt-2"
                          onClick={() => {
                            if (upcomingLocations) {
                              openUpcomingLocation(
                                upcomingLocations.Address
                              );
                            } else {
                              console.error("No upcoming location available");
                            }
                          }}
                        >
                          Upcoming Location on Maps
                        </button>
                      </div>
                    ) : (
                      <p className="text-muted mt-3">
                        No upcoming locations available.
                      </p>
                    )}
                  </div>
                </div>

                {/* ✅ Footer */}
                {/* <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div> */}
              </div>
            </div>
          </div>

          {/* ✅ Modal Backdrop */}
          <div
            className="modal-backdrop fade show"
            onClick={() => setShowModal(false)}
          />
        </>
      )}
    </div>
  );
}
