import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconKey from "../../../../component/Icons/IconKey";
import api from "../../../../services/api";
import { useToast } from "../../../../services/Toster";
import IconLock from "../../../../component/Icons/IconLock";
import IconSignIn from "../../../../component/Icons/IconSignIn";
import "./style.css";
import IconShowEye from "../../../../component/Icons/IconShowEye";
import EmailIcon from "../../../../component/Icons/EmailIcon";

export default function Login() {
  const notify = useToast();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle Forgot Password in a single API call
  const handleForgotPassword = async (e) => {
    e.preventDefault();
  
    const { email } = formData;
  
    if (!email) {
      notify("error", "Please enter your email.");
      return;
    }
  
    try {
      setIsLoading(true);
      const response = await api.post("/forgot-password", { email });
  
      if (response.status === 200) {
        notify("success", "Password reset link sent to your email.");
        setFormData((prev) => ({
          ...prev,
          email: "",
        }));
      }
    } catch (error) {
      notify("error", error?.response?.data?.message || "Failed to send reset link.");
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.post(
        "/login",
        { identifier: formData.username, password: formData.password },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        const { role, Name, UserId, UserEmailId, UserMobile, token } = response.data;

        localStorage.setItem("authToken", token);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("role", role);
        localStorage.setItem("UserId", UserId);
        localStorage.setItem("Name", Name);
        localStorage.setItem("UserEmailId", UserEmailId);
        localStorage.setItem("UserMobile", UserMobile);

        notify("success", "Login successful");
        if (role === "Admin") {
          navigate("/manage-regular-categories");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        notify("error", error.response.data.message);
      } else if (error.request) {
        console.log("Request made but no response:", error.request);
        if (error.code === "ERR_NETWORK") {
          notify("error", "Network error. Please try again later.");
        } else {
          notify("error", "An error occurred. Please try again.");
        }
      } else {
        console.log("Error setting up request:", error.message);
        if (error.code === "ERR_NETWORK") {
          notify("error", "Network error. Please try again later.");
        } else {
          notify("error", "An error occurred. Please try again.");
        }
      }      
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="loginpage">
      <form className="loginform" onSubmit={isForgotPassword ? handleForgotPassword : handleLogin}>
        <div className="loginlogo">
          <img src="../images/logo.svg" alt="Spice on Wheel" />
          <h5>{isForgotPassword ? "Forgot Password" : "Sign in to start your session"}</h5>
        </div>

        {!isForgotPassword ? (
          <>
            {/* Username Field */}
            <div className="formfield">
              <input
                type="text"
                name="username"
                placeholder="Enter Email"
                value={formData.username}
                onChange={handleChange}
                disabled={isLoading}
              />
              <span>
                <IconLock />
              </span>
            </div>

            {/* Password Field */}
            <div className="formfield">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                value={formData.password}
                onChange={handleChange}
                disabled={isLoading}
              />
              <span onClick={() => setShowPassword(!showPassword)} style={{ cursor: "pointer" }}>
                {showPassword ? <IconKey /> : <IconShowEye />}
              </span>
            </div>

            <button type="submit" disabled={isLoading}>
              {isLoading ? "Signing in..." : <>Sign in <IconSignIn /></>}
            </button>

            <Link to="#" className="forgatepassword" onClick={() => setIsForgotPassword(true)}>
              Forgot Password?
            </Link>
          </>
        ) : (
          <div className="emailform">
            {/* Email Field */}
            <div className="formfield">
              <input
                type="email"
                name="email"
                placeholder="Enter Email"
                value={formData.email}
                onChange={handleChange}
                disabled={isLoading}
              />
              <span>
                <EmailIcon />
              </span>
            </div>         

            <button type="submit" disabled={isLoading}>
              {isLoading ? "Resetting..." : "Reset Password"}
            </button>

            <Link to="#" className="forgatepassword" onClick={() => setIsForgotPassword(false)}>
              Back to Login
            </Link>
          </div>
        )}
      </form>
    </section>
  );
}
