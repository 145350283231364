import React from "react";

export default function IconEdit() {
  return (
    <svg
      id="fi_18723085"
      viewBox="0 0 800 800"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m400 758.3c-80.8 0-139.2 0-186.2-6.3-52.9-7.1-88.6-22.5-116-49.8-27.3-27.3-42.7-63.1-49.8-116-6.3-47-6.3-105.4-6.3-186.2s0-139.2 6.3-186.2c7.1-52.9 22.5-88.6 49.8-116s63.1-42.7 116-49.8c47-6.3 105.4-6.3 186.2-6.3h50c13.8 0 25 11.2 25 25s-11.2 25-25 25h-50c-150 0-225.3 0-266.8 41.5s-41.5 116.8-41.5 266.8 0 225.3 41.5 266.8 116.8 41.5 266.8 41.5 225.3 0 266.8-41.5 41.5-116.8 41.5-266.8v-50c0-13.8 11.2-25 25-25s25 11.2 25 25v50c0 80.8 0 139.2-6.3 186.2-7.1 52.9-22.5 88.6-49.8 116-27.3 27.3-63.1 42.7-116 49.8-47 6.3-105.4 6.3-186.2 6.3z"></path>
        <path d="m291.3 558.4c-12.9 0-25.5-5.1-35.1-14.6-13.4-13.4-18-32.9-12-50.9l31.8-95.3c6.3-19 9.5-28.6 14.2-38.5 5.6-11.6 12.4-22.7 20.3-32.9 6.7-8.6 13.8-15.7 28-29.9l198.9-198.8 21.6-21.6c45.5-45.5 119.6-45.5 165.1 0s45.5 119.6 0 165.1l-220.4 220.4c-14.2 14.2-21.3 21.3-29.9 28-10.2 7.9-21.2 14.8-32.9 20.3-9.9 4.7-19.4 7.9-38.4 14.2l-57.9 19.3-37.4 12.5c-5.3 1.8-10.6 2.7-15.9 2.7zm8.7-74.7-8.2 24.5 24.5-8.2zm17.7-53 51.7 51.7 17.3-5.8c17.1-5.7 25.6-8.5 32.8-11.9 8.4-4 16.3-8.9 23.6-14.6 6.2-4.8 12.6-11.2 25.3-24l168.6-168.6c-17.3-7.4-37.3-19.3-56.2-38.2s-30.7-38.9-38.2-56.2l-168.7 168.6c-12.7 12.7-19.1 19.1-24 25.3-5.7 7.3-10.6 15.3-14.6 23.6-3.4 7.1-6.2 15.7-11.9 32.8zm264.1-306.9c3.1 13.4 11.6 37.6 34.2 60.2 22.5 22.5 46.9 31 60.2 34.2l12.6-12.6c26-26 26-68.4 0-94.4s-68.4-26-94.4 0z"></path>
      </g>
    </svg>
  );
}
