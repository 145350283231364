import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import EmailIcon from "../../component/Icons/EmailIcon";
import InstagramIcon from "../../component/Icons/InstagramIcon";
import "./style.css";
import LocationIcon from "../Icons/LocationIcon";
import IconWatch from "../Icons/IconWatch";
import FooterBG from "../Icons/FooterBG";
import api from "../../services/api";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import IconCalendar from "../Icons/IconCalendar";
import { MultiSelect } from "react-multi-select-component";

const libraries = ["places"]; // Ensure the "places" library is loaded

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [curOrNextlocationDetails, setcurOrNextLocationDetails] = useState(null); // To store API data
  const [calenderlocationDetails, setcalenderLocationDetails] = useState(null); // To store API data
  const [TuesdaylocationDetails, setTuesdayLocationDetails] = useState(null);
  const [ThursdaylocationDetails, setThursdayLocationDetails] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 23.0292366,
    lng: 72.5778432,
  });
  const currentDay = new Date().toLocaleDateString("en-US", { weekday: "long" });

  const [selectedDays, setSelectedDays] = useState([
    { label: currentDay, value: currentDay },
  ]);
  const [selectedDay, setSelectedDay] = useState(currentDay);
  const [mapKey, setMapKey] = useState(0);
  const footerRef = useRef(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDVwmhfEaJg-hWXo24p04WFjJNAPfF0Cz4",
    libraries,
  });

  // Observe the visibility of the footer
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFooterVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );
    if (footerRef.current) {
      observer.observe(footerRef.current);
    }
    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Update map key when selectedDay changes to force re-render
    setMapKey((prevKey) => prevKey + 1);
    const fetchLocationDetails = async () => {
      try {
        const response = await api.post(
          "/getLocation",
          { selDayName: selectedDay },
          {},
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        console.log("Full API response:", response);

        if (response.data && response.data.data) {
          setcalenderLocationDetails(response.data.data);
          const lat = parseFloat(response.data.data.Latitude);
          const lng = parseFloat(response.data.data.Longitude);

          // ✅ Ensure values are valid numbers before setting state
          if (!isNaN(lat) && !isNaN(lng)) {
            setMapCenter({ lat, lng });
          } else {
            console.error("Invalid Latitude or Longitude:", lat, lng);
          }
        }
      } catch (error) {
        console.error("Error fetching location details:", error);
      }
    };

    fetchLocationDetails();

  }, [selectedDay]);

  useEffect(() => {
    const fetchTuesdayLocation = async () => {
      try {
        const response = await api.post(
          "/getLocation",
          { selDayName: "Tuesday" },
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log("Tuesday Location:", response);
        if (response.data && response.data.data) {
          if (response.data.data.Day_Name == 'Tuesday') {
            setTuesdayLocationDetails(response.data.data);
          }
        }
      } catch (error) {
        console.error("Error fetching Tuesday location details:", error);
      }
    };

    fetchTuesdayLocation();

    const fetchThursdayLocation = async () => {
      try {
        const response = await api.post(
          "/getLocation",
          { selDayName: "Thursday" },
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log("Thursday Location:", response);
        if (response.data && response.data.data) {
          if (response.data.data.Day_Name == 'Thursday') {
            setThursdayLocationDetails(response.data.data);
          }
        }
      } catch (error) {
        console.error("Error fetching Thursday location details:", error);
      }
    };

    fetchThursdayLocation();


    const fetchCurorNextLocation = async () => {
      try {
        const response = await api.post(
          "/getLocation",
          {},
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log("Current or Next Location:", response);
        if (response.data && response.data.data) {
          setcurOrNextLocationDetails(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching Current or Next location details:", error);
      }
    };

    fetchCurorNextLocation();

  }, []); // Runs only once on mount

  const formatDateTime = (isoString) => {
    if (!isoString) return { date: "", day: "", time: "" };
    const dateObj = new Date(isoString);

    const date = dateObj
      .toLocaleDateString("en-GB")
      .replace(/\//g, "."); // Convert slashes to hyphens

    const day = dateObj.toLocaleDateString("en-US", { weekday: "long" });
    const time = dateObj.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    return { date, day, time };
  };

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const dayOptions = daysOfWeek.map((day) => ({ label: day, value: day }));



  return (
    <div className="locationcover" ref={footerRef}>
      <section className="section todaylocationsection">
        <div className="titleone text-center">
          <h4>Today Live Location</h4>
        </div>
        <div className="bigscreen">
          <div className="container">
            <div className="week-tabs">
              {daysOfWeek.map((day) => (
                <button
                  key={day}
                  className={`desktopview week-tab ${selectedDay === day ? "active" : ""}`}
                  onClick={() => setSelectedDay(day)}
                >
                  {day}
                </button>
              ))}


              <MultiSelect
                className="inputbox mobileview"
                options={dayOptions}
                labelledBy="Select Days"
                hasSelectAll={false}
                value={selectedDays}
                onChange={(selected) => {
                  const lastSelected = selected.slice(-1); // Keep only the last selection
                  setSelectedDays(lastSelected);
                  if (lastSelected.length > 0) {
                    setSelectedDay(lastSelected[0].value); // Update selectedDay
                  }
                  // ✅ Close dropdown after selection
                  document.activeElement?.blur();
                }}
                valueRenderer={(selected) => selected.map((item) => item.label).join(", ")}
              />


            </div>

            <div className="livelocation-main">
              {selectedDay ===
                formatDateTime(calenderlocationDetails?.Date_Time_From).day ? (
                <>
                  <div className="livelocation">
                    {isLoaded && (
                      <GoogleMap
                        key={mapKey}
                        mapContainerClassName="map-container"
                        center={mapCenter}
                        zoom={15}
                      >
                        {/* {console.log("Rendering Map with Center:", mapCenter)} */}
                        <Marker position={mapCenter} />
                      </GoogleMap>
                    )}
                  </div>
                  <div className="boxaddresh">
                    <h3>Current Availability at the Given Address</h3>

                    {/* Address */}
                    <div className="contactinfo">
                      <span>
                        <LocationIcon /> Address
                      </span>
                      <p>{calenderlocationDetails?.Address}</p>
                    </div>

                    {/* Date & Day */}
                    <div className="contactinfo">
                      <span>
                        <IconCalendar /> Date & Day
                      </span>
                      <p>
                        {formatDateTime(calenderlocationDetails?.Date_Time_From).date} , {formatDateTime(calenderlocationDetails?.Date_Time_From).day}
                      </p>
                    </div>

                    {/* Time */}
                    <div className="contactinfo">
                      <span>
                        <IconWatch /> Time
                      </span>
                      <p>
                        From{" "}
                        {formatDateTime(calenderlocationDetails?.Date_Time_From).time} -
                        To {formatDateTime(calenderlocationDetails?.Date_Time_To).time}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="not-available flex items-center justify-center">
                  <p>No location available.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <footer className="section">
        {isFooterVisible && <FooterBG isVisible={isFooterVisible} />}

        {!isFooterVisible && (
          <div className="footerlocation">
            {/* <a
              rel="noopener noreferrer"
              target="_blank"
              title="Live Location"
              //href={`https://www.google.com/maps?q=${locationDetails?.Latitude},${locationDetails?.Longitude}`}
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(locationDetails?.Address)}`}
              className="locationbtn"
            >
              <p>
                <LocationIcon />
              </p>
              <span>{formatDateTime(locationDetails?.Date_Time_From).date} </span> 
                <span>{formatDateTime(locationDetails?.Date_Time_From).day}</span> 
                <span>From {formatDateTime(locationDetails?.Date_Time_From).time} - To {formatDateTime(locationDetails?.Date_Time_To).time}</span>              
            </a> */}
            <div className="locationbtn twoloactions">

              <p>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Live Location"

                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(curOrNextlocationDetails?.Address)}`}

                >
                  <LocationIcon />
                </a>
              </p>

              <a
                rel="noopener noreferrer"
                target="_blank"
                title="Tuesday Location"
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(TuesdaylocationDetails?.Address)}`}>
                <span>Tuesday Location</span>
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                title="Thursday Location"
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(ThursdaylocationDetails?.Address)}`}
              >
                <span>Thursday Location</span>
              </a>
            </div>
          </div>
        )}

        {/* Footer content */}
        <div className="footer-main">
          <div className="container">
            <Link className="logofooter" to="/">
              <img src="../images/logo.svg" alt="Spice on Wheel" />
            </Link>

            <div className="socialmedia">
              <a href="mailto:spiceonwheels@web.de">
                <EmailIcon />
              </a>
              <Link
                to="https://www.instagram.com/spiceonwheels.de"
                target="_blank"
              >
                <InstagramIcon />
              </Link>
            </div>
          </div>
        </div>

        <div className="copyrightfirst">
          <p>© {currentYear} Spice on wheels</p>
          <div className="copyright">
            <div className="container">
              <div className="bottomfooter">
                <p>
                  Design, Developed & Managed by{" "}
                  <a
                    href="https://globalsoftwares.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Global Software</strong>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
