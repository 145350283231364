import React, { useState, useRef, useEffect } from "react";
import AdminHeader from "../../../../component/AdminComponent/AdminHeader";
import "./style.css";
import api from "../../../../services/api";
import { useToast } from "../../../../services/Toster";
import { GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { Link } from "react-router-dom";
import IconLoaction from "../../../../component/Icons/IconLoaction";
import IconSearch from "../../../../component/Icons/IconSearch";
import PaginationWidget from "../../../../component/AdminComponent/PaginationWidget";
const libraries = ["places"]; // Ensure the "places" library is loaded
const initialFormData = {
  Date_From: new Date().toISOString().split("T")[0],
  Time_From: "",
  Time_To: "",
  Latitude: "",
  Longitude: "",
  Address: "",
  Day_Name:"",
  Time_From:"",
  Time_To:""
};


const ENTRIES_PER_PAGE = 10;

export default function AddLocation() {
  const [formattedData, setFormattedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const notify = useToast();
  const [formData, setFormData] = useState(initialFormData);
  const [previousLocations, setPreviousLocations] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: 23.0292366, lng: 72.5778432 });
  const [showmodal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const searchBoxRef = useRef(null);
  const [mapKey, setMapKey] = useState(0);

  const handleDayChange = (e) => {
    const selDay = e.target.value;
    const selDate = getNextDatefromday(selDay -1);
    const selDayName = e.target.options[e.target.selectedIndex].text;
    setFormData({
      ...formData,
      Date_From: selDate,
      Day_Name: selDayName
    });

  };

  function getNextDatefromday(dayNo) {
    let today = new Date();
    let dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    let daysUntilNext = (dayNo - dayOfWeek + 7) % 7 || 7; // Days until next Tuesday
    let nextDay = new Date();
    nextDay.setDate(today.getDate() + daysUntilNext);
      // Extract YYYY-MM-DD
      let datePart = nextDay.toISOString().split('T')[0]; 
      // Append the provided time (in HH:MM format) with seconds defaulted to 00
      return `${datePart}`; // Format: YYYY-MM-DD HH:MM:SS
  }
  

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      const response = await api.post("/listlocations", {}, {
        headers: { "Content-Type": "application/json" },
      });
      setFormattedData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  // Load Google Maps API only once
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDVwmhfEaJg-hWXo24p04WFjJNAPfF0Cz4",
    libraries
  });

  if (loadError) console.error("Google Maps API failed to load:", loadError);

  const filteredLocations = previousLocations.filter((location) =>
    location.Address.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchPreviousLocations = async () => {
    try {
      const response = await api.post('/listlocations', {}, {
        headers: { "Content-Type": "application/json" },
      });;
      if (Array.isArray(response.data.data)) {
        const uniqueLocations = response.data.data.filter(
          (location, index, self) =>
            index === self.findIndex((loc) => loc.Address === location.Address)
        );
        const reversedData = uniqueLocations.reverse();
        setPreviousLocations(reversedData);
      } else {
        setPreviousLocations([]);
        notify('error', 'Failed to load locations.');
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
      notify('error', 'An error occurred while loading locations.');
    }
  };

  const openModal = () => {
    fetchPreviousLocations(); // Fetch locations when opening modal
    setShowModal(true);
  };

  const handleSelectLocation = (location) => {
    setFormData({
      ...formData,
      Latitude: location.Latitude,
      Longitude: location.Longitude,
      Address: location.Address,
    });
    setMapCenter({ lat: parseFloat(location.Latitude), lng: parseFloat(location.Longitude) });
    setShowModal(false);
  };
  const handleDeleteLocation = async (id) => {
    try {
      const response = await api.post(`/deleteLocation` ,{ id });
      if (response.status === 200) {
        // setPreviousLocations(previousLocations.filter((loc) => loc.id !== id));
        setFormattedData(formattedData.filter((loc) => loc.ID !== id))
        notify("success", "Location deleted successfully.");
      }
    } catch (error) {
      console.error("Error deleting location:", error);
      notify("error", "Failed to delete location.");
    }
  };


  // Handle input changes for Date, Time, Latitude, and Longitude
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "Latitude" || name === "Longitude") {
      const lat = parseFloat(name === "Latitude" ? value : formData.Latitude) || 0;
      const lng = parseFloat(name === "Longitude" ? value : formData.Longitude) || 0;
      setMapCenter({ lat, lng });
    }
  };
  const forceRerender = () => {
    setMapKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    forceRerender();
  }, [mapCenter]);
  // Handle marker drag event



  const handlePlaceSelect = () => {
    try {
      const places = searchBoxRef.current.getPlaces();
      if (!places || places.length === 0) {
        // notify("error", "No places found. Please try again.");
        return;
      }

      const place = places[0];
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      // Update both mapCenter and formData
      setMapCenter({ lat, lng });
      setFormData({ ...formData, Latitude: lat, Longitude: lng });

      // Update the search text for UI feedback
      setSearchText(place.formatted_address);
    } catch (error) {
      console.error("Error in handlePlaceSelect:", error);
      notify("error", "Unable to select the place. Please try again.");
    }
  };

  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    console.log("New marker position:", { lat, lng });
    setFormData({ ...formData, Latitude: lat, Longitude: lng });
    // setMapCenter({ lat, lng });
    handlePlaceSelect();
  };
  // Handle place selection from Google Places Autocomplete


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.Date_From || !formData.Time_From || !formData.Time_To || !formData.Latitude || !formData.Longitude || !formData.Address) {
      notify("error", "Please fill in all required fields!");
      return;
    }

    const submissionData = {
      Date_Time_From: `${formData.Date_From} ${formData.Time_From}:00`,
      Date_Time_To: `${formData.Date_From} ${formData.Time_To}:00`,
      Latitude: formData.Latitude,
      Longitude: formData.Longitude,
      // Longitude: formData.Longitude,
      Address: formData.Address,
      Day_Name: formData.Day_Name,
      Time_From: formData.Time_From,
      Time_To: formData.Time_To
    };

    try {
      const response = await api.post("/updateLocation", submissionData, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.data) {
        notify("success", "Location saved successfully.");
        document.querySelector("select[name='days']").value = ""
        setFormData(initialFormData);
        fetchLocations(); // Refresh the grid
      } else {
        notify("error", "Some exception has occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      notify("error", "An error occurred while submitting the form.");
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          if (latitude !== mapCenter.lat || longitude !== mapCenter.lng) {
            setMapCenter({ lat: latitude, lng: longitude });
            setFormData({ ...formData, Latitude: latitude, Longitude: longitude });
          }
        },
        (error) => console.error("Error fetching location:", error)
      );
    }
  }, []);
  // Calculate total pages and displayed data based on current page
  const totalPages = Math.ceil(formattedData.length / ENTRIES_PER_PAGE);
  const displayedData = formattedData.slice(
    (currentPage - 1) * ENTRIES_PER_PAGE,
    currentPage * ENTRIES_PER_PAGE
  );

  return (
    <section className="locationpage">
      <AdminHeader pageTitle="Manage Locations" />
      <div className="pageLeftspace">
        <div className="tableheader flex-column gap-4 d-flex justify-content-between align-items-center">
          <form className="locationform" onSubmit={handleSubmit}>
            <div className="w-full flex gap-5 items-end flex-column flex-md-row">
            {/*<div className="form-group">
              <label>Select Date <small>*</small></label>
              <input type="date" className="form-control" required name="Date_From" value={formData.Date_From} onChange={handleInputChange} />
            </div> */}
          <div className="form-group">
          <label>Select Day <small>*</small></label>
          <select name="days" className="form-control" onChange={handleDayChange} required>
            <option value="">Select day</option>
            <option value="1">Sunday</option>            
            <option value="2">Monday</option>
            <option value="3">Tuesday</option>            
            <option value="4">Wednesday</option>
            <option value="5">Thursday</option>            
            <option value="6">Friday</option>
            <option value="7">Saturday</option>            
          </select>
          </div>

              <div className="form-group">
                <label>Start Time (German Time) <small>*</small></label>
                <input type="time" className="form-control" required name="Time_From" value={formData.Time_From} onChange={handleInputChange} />
              </div>
              <div className="form-group">
                <label>End Time (German Time) <small>*</small></label>
                <input type="time" className="form-control" required name="Time_To" value={formData.Time_To} onChange={handleInputChange} />
              </div>
              <div className="form-group selectprev">
                <label>Select Previous Location</label>
                <Link className="btn btn-outline-primary" onClick={openModal}>
                  <IconLoaction />  Previous Location
                </Link>
              </div>
            </div>
            <div className="w-full flex gap-5 align-items-end flex-column flex-md-row">

              {isLoaded && (
                <div className="form-group locationsearch">
                  <label>Search Location</label>
                  <StandaloneSearchBox
                    onLoad={(ref) => {
                      searchBoxRef.current = ref;
                    }}
                    onPlacesChanged={handlePlaceSelect}>
                    <input
                      type="text"
                      placeholder="Search Location"
                      className="form-control"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </StandaloneSearchBox>
                </div>
              )}

              <div className="form-group">
                <label>Latitude <small>*</small></label>
                <input required type="text" className="form-control" name="Latitude" value={formData.Latitude} onChange={handleInputChange} />
              </div>
              <div className="form-group">
                <label>Longitude <small>*</small></label>
                <input required type="text" className="form-control" name="Longitude" value={formData.Longitude} onChange={handleInputChange} />
              </div>

              <div className="form-group">
                <label>Address <small>*</small></label>
                <input required type="text" className="form-control" name="Address" value={formData.Address} onChange={handleInputChange} />
              </div>

              <button className="btn" type="submit"> Save Location </button>
            </div>
          </form>

          {/* Google Map Display */}
          {isLoaded && (
            <div className="showLocation">
              <GoogleMap key={mapKey} mapContainerStyle={{ height: "400px", width: "100%" }} center={mapCenter} zoom={15}>
                {/* {console.log("Rendering Map with Center:", mapCenter)} */}
                <Marker position={mapCenter} draggable={true} onDragEnd={handleMarkerDragEnd} />
              </GoogleMap>
            </div>
          )}

<div className="tablescroll">
            <table className="table table-bordered mb-3">
              <thead>
                <tr>
                  <th width="40">Sr. No.</th>
                  <th>Date</th>
                  <th>Day</th>
                  <th>From Time</th>
                  <th>To Time</th>
                  <th>Address</th>
                  <th width="60">Action</th>
                </tr>
              </thead>
              <tbody>
                {displayedData.length > 0 ? (
                  displayedData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td align="center">{rowIndex + 1}</td>
                      <td>                        
                        {new Date(row.Date_Time_From)
                          .toLocaleDateString("de-DE", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })}
                      </td>
                      <td>
                        {/* {new Date(row.Date_Time_From).toLocaleDateString("en-GB", { weekday: "long" })} */}
                        {row.Day_Name}
                      </td>
                      <td>
                        {new Date(row.Date_Time_From).toLocaleTimeString("en-GB", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false, // Use 24-hour format
                        })} {/* Format as time */}
                      </td>
                      <td>
                        {new Date(row.Date_Time_To).toLocaleTimeString("en-GB", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false, // Use 24-hour format
                        })} {/* Format as time */}
                      </td>
                      <td>{row.Address}</td>
                      <td><button className="btn" onClick={() => handleDeleteLocation(row.ID)}>Delete</button></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      align="center"
                      style={{ padding: "10px" }}
                    >
                      No locations available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {totalPages > 1 && (
              <PaginationWidget
                totalPages={totalPages}
                onPageChanged={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>

      <div className={`modal fade ${showmodal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
        <div className="loactionmodal modal-dialog h-screen flex items-center m-auto max-w-4xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Previous Locations</h5>
              <div className="search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Locations name here..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <IconSearch />
              </div>

              <button type="button" className="btn-close" onClick={() => setShowModal(false)} />
            </div>
            <div className="modal-body overflow-y-auto">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th width="58">Sr. No.</th>
                    <th>Location</th>
                    <th width="142" style={{ textAlign: "center" }}>Action</th>
                    {/* <th width="70" style={{ textAlign: "center" }}>Delete</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredLocations.length > 0 ? (
                    filteredLocations.map((location, index) => (
                      <tr key={index}>
                        <td align="center">{index + 1}</td>
                        <td>{location.Address}</td>
                        <td>
                          <Link
                            to="#"
                            className="btn primerybutton"
                            onClick={() => handleSelectLocation(location)}
                          >
                            Select Location
                          </Link>
                        </td>
                        {/* <td><button className="btn btn-danger" onClick={() => handleDeleteLocation(location.Sr_No)}>Delete</button></td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        No previous locations found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showmodal && <div className="modal-backdrop fade show" onClick={() => setShowModal(false)}></div>}
    </section>
  );
}

