import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from '../../pages/Home'
import Location from '../../pages/Location'
import PreOrder from '../../pages/PreOrder'
import ContactUs from '../../pages/ContactUs'
import FoodItems from '../../pages/FoodItems'
import { Gallery } from '../../pages/Gallery'
import Catering from '../../pages/Catering'
import AboutUs from '../../pages/AboutUs'
import Login from '../../pages/Admin/pages/Login'
import ManageCategories from '../../pages/Admin/pages/Categories'
import ManageRegularCategories from '../../pages/Admin/pages/RegularCategories'
import AddDishes from '../../pages/Admin/pages/AddDishes'
import ManageRegularDishes from '../../pages/Admin/pages/ManageRegularDishes'
import AddLocation from '../../pages/Admin/pages/AddLocation'
import AddGallery from '../../pages/Admin/pages/AddGallery'
import ContactList from '../../pages/Admin/pages/ManageContact_Us'
import PreorderList from '../../pages/Admin/pages/ListPreorder'
import ResetPassword from '../../pages/ResetPassword'
import OrderReject from '../../pages/OrderReject'

export default function NavRouting({loading}) {
   const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/admin') ||
    location.pathname.startsWith('/manage-categories') ||
    location.pathname.startsWith('/manage-regular-categories') ||
    location.pathname.startsWith('/manage-regular-dishes') ||
    location.pathname.startsWith('/add-location') ||
    location.pathname.startsWith('/add-gallery') ||
    location.pathname.startsWith('/contact-list') ||
    location.pathname.startsWith('/Preorder-list') ||
     location.pathname.startsWith('/add-dishes')) {
      document.documentElement.style.height = "100%"; 
      document.body.style.height = "100%"; 
    } else {
      document.documentElement.style.height = "auto";  
      document.body.style.height = "auto";  
    }
  }, [location]);
  return (
    <Routes>
        <Route path="/" element={<Home loading={loading} />} />
        <Route path="gallery" element={<Gallery/>} />
        <Route path="about-us" element={<AboutUs/>} />        
        <Route path="location" element={<Location/>} />
        <Route path="catering" element={<Catering/>} />        
        <Route path="pre-order" element={<PreOrder/>} />        
        <Route path="menu" element={<FoodItems/>} />  
        <Route path="contact-us" element={<ContactUs/>} />        
        <Route path="order-reject/:rejectToken" element={<OrderReject/>} />                
        {/* admin pages */}
        <Route path="/admin" element={<Login />}/>      
        <Route path="/reset-password/:token" element={<ResetPassword/>}/>              
        <Route path="/manage-regular-categories" element={<ManageRegularCategories/>} />
        <Route path="/manage-regular-dishes" element={<ManageRegularDishes/>} />
        <Route path="/manage-categories" element={<ManageCategories/>} />       
        <Route path="/add-dishes" element={<AddDishes/>} />        
        <Route path="/add-location" element={<AddLocation/>} /> 
        <Route path="/add-gallery" element={<AddGallery/>} />       
        <Route path="/contact-list" element={<ContactList/>} />        
        <Route path="/Preorder-list" element={<PreorderList/>} />        
      </Routes>     
  )
}
