import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import PageLayout from '../../component/PageLayout';
import api from '../../services/api';
import { useToast } from '../../services/Toster';

export default function OrderReject() {
  const { rejectToken } = useParams(); // Capture token from URL
  const notify = useToast();

  const [formData, setFormData] = useState({
    Order_No: '',
    Order_Date_Time:'',
    Cutomer_Name:'',
    Customer_Email_ID:'',
    Customer_Mob_No:'',
    rejectionReason: '',
    rejectToken: rejectToken || '', // Store token in state
  });

  const [isLoading, setIsLoading] = useState(false);

  // ✅ Fetch order details using rejectToken
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        if (!rejectToken) {
          notify("error", "Invalid or missing rejection token.");
          return;
        }

        const response = await api.get(`/get_preorder/${rejectToken}`);

        if (response.status === 200) {
          setFormData((prev) => ({
            ...prev,
            Order_No: response.data.data.Order_No, // ✅ Set order number from backend
            Order_Date_Time:response.data.data.Order_Date_Time,
            Cutomer_Name:response.data.data.Cutomer_Name,
            Customer_Email_ID:response.data.data.Customer_Email_ID,
            Customer_Mob_No:response.data.data.Customer_Mob_No,
          }));
        }
      } catch (error) {
        notify("error", "Failed to fetch order details.");
      }
    };

    fetchOrderDetails();
  }, [rejectToken, notify]);

  // ✅ Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // ✅ Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.rejectionReason) {
      notify("error", "Please enter a rejection message.");
      return;
    }

    try {
      setIsLoading(true);

      // ✅ Call API to reject order
      const response = await api.post("/reject-order", formData);

      if (response.status === 200) {
        notify("success", "Order rejected successfully!");
        setFormData({ Order_No: '', rejectionReason: '', rejectToken: '' });
      }
    } catch (error) {
      notify("error", error?.response?.data?.message || "Failed to reject order.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageLayout>
      <section className="section ptinner preorder">
        <div className="titleone text-center">
          <h4>Reject Order</h4>
        </div>
        <div className="bigscreen pagewidth">
          <div className="container">
            <div className="bookform">
              <div className="bookform-left">
                <form onSubmit={handleSubmit}>
                  {/* ✅ Read-Only Order Number Input */}
                  <div className="form_input">
                    <label>Order No.<small>*</small></label>
                    <input
                      className="inputbox"
                      type="text"
                      name="Order_No"
                      value={formData.Order_No}
                      readOnly // ✅ Make Order No read-only
                    />
                  </div>

                  <div className="form_input">
                    <label>Order Date<small>*</small></label>
                    <input
                      className="inputbox"
                      type="text"
                      name="Order_Date_Time"
                      value={formData.Order_Date_Time}
                      readOnly // ✅ Make Order No read-only
                    />
                  </div>
                  <div className="form_input">
                    <label>Customer Name<small>*</small></label>
                    <input
                      className="inputbox"
                      type="text"
                      name="Cutomer_Name"
                      value={formData.Cutomer_Name}
                      readOnly // ✅ Make Order No read-only
                    />
                  </div>
                  <div className="form_input">
                    <label>email Address<small>*</small></label>
                    <input
                      className="inputbox"
                      type="text"
                      name="Customer_Email_ID"
                      value={formData.Customer_Email_ID}
                      readOnly // ✅ Make Order No read-only
                    />
                  </div>

                  <div className="form_input">
                    <label>Mobile Number<small>*</small></label>
                    <input
                      className="inputbox"
                      type="text"
                      name="Customer_Mob_No"
                      value={formData.Customer_Mob_No}
                      readOnly // ✅ Make Order No read-only
                    />
                  </div>

                  {/* ✅ Message Input */}
                  <div className="textarea">
                    <label>Enter Your Message</label>
                    <textarea
                      rows={4}
                      name="rejectionReason"
                      placeholder="Enter Your Rejection Message Here..."
                      value={formData.rejectionReason}
                      onChange={handleChange}
                      required
                      disabled={isLoading}
                    />
                  </div>

                  {/* ✅ Submit Button */}
                  <div className="form-btn">
                    <button type="submit" className="btn submitForm" disabled={isLoading}>
                      {isLoading ? "Loading..." : "Reject"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}
