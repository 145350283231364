import React, { useRef, useState } from "react";
import { CardBody, CardContainer, CardItem } from "./CardEffect";
import "./style.css";
import { Link } from "react-router-dom";

export default function OurService() {
  const cardData = [
    {
      src: "../images/foodtrack.webp",
      alt: "foodtrack",
      servicetitle: "Food Truck",
      pagelink: "/menu",
    },
    {
      src: "../images/catering-pic.webp",
      alt: "catering-pic",
      servicetitle: "Catering",
      pagelink: "/catering",
    },
  ];
  const [imageLoading, setImageLoading] = useState(
    cardData.reduce((acc, _, index) => ({ ...acc, [index]: true }), {})
  );
  const imgRefs = useRef([]);
  const handleImageLoad = (index) => {
    setImageLoading((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  return (
    <section className="section OurService">
      <div className="titleone text-center">
        <h4>Our Service</h4>
        {/* <h3>Food Truck and Catering</h3> */}
      </div>
      <div className="bigscreen">
        <div className="container">
          <div className="service-main">
            {cardData.map((card, index) => (
              <CardContainer className="inter-var flex-col gap-5" key={index}>
                <CardBody className="relative group/card dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] h-auto rounded-xl">
                  <CardItem translateZ="100">
                    <Link to={card.pagelink} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                      <div
                        className={`cateringsection relative w-full object-cover rounded-xl group-hover/card:shadow-xl ${
                          imageLoading[index] ? "bg-gray-300" : ""
                        }`}
                      >
                        <img
                          ref={(el) => (imgRefs.current[index] = el)}
                          src={card.src}
                          className="w-full object-cover rounded-xl"
                          alt={card.servicetitle}
                          loading="lazy"
                          onLoad={() => handleImageLoad(index)}
                        />
                      </div>
                    </Link>
                  </CardItem>
                </CardBody>
                <Link to={card.pagelink} className="servicetitle">
                  {card.servicetitle}
                </Link>
              </CardContainer>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
