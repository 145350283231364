import React, { useEffect, useState } from "react";
import AdminHeader from "../../../../component/AdminComponent/AdminHeader";
import api from "../../../../services/api";
import PaginationWidget from "../../../../component/AdminComponent/PaginationWidget";
import IconSearch from "../../../../component/Icons/IconSearch";

const ENTRIES_PER_PAGE = 20;

export default function ContactList() {
  const [formattedData, setFormattedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const response = await api.post(
          "/list_contact_us",
          {},
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log("response", response.data.data);

        if (isMounted) {
          const reversedData = response.data.data.reverse();
          setFormattedData(reversedData);
          setFilteredData(reversedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

    // Handle search input change
    const handleSearch = (e) => {
      const value = e.target.value;
      setSearchTerm(value);
  
      if (value.trim() === "") {
        setFilteredData(formattedData);
      } else {
        const lowercasedValue = value.toLowerCase();
        const filtered = formattedData.filter((item) =>
          Object.values(item).some(
            (field) =>
              field &&
              field.toString().toLowerCase().includes(lowercasedValue)
          )
        );
        setFilteredData(filtered);
      }
      setCurrentPage(1); // Reset to first page after filtering
    };

  // Calculate total pages and displayed data based on current page
  const totalPages = Math.ceil(formattedData.length / ENTRIES_PER_PAGE);
  const displayedData = filteredData.slice(
    (currentPage - 1) * ENTRIES_PER_PAGE,
    currentPage * ENTRIES_PER_PAGE
  );

  return (
    <section className="locationpage">
      <AdminHeader pageTitle="List of Contacts" />
      <div className="pageLeftspace">
        <div className="tableheader flex-column gap-4 d-flex justify-content-between align-items-center">
        <div className="search-bar w-full">
            <input
            type="text"
            className="form-control"
            placeholder="Search Contact Details..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <IconSearch/>
            </div>

          <div className="tablescroll">
            <table className="table table-bordered mb-3">
              <thead>
                <tr>
                  <th width="60">Sr. No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile No.</th>
                  <th>Subject</th>
                  <th>Message</th>
                  <th>Date & Time</th>

                  {/* {displayedData.length > 0 &&
                  Object.keys(displayedData[0]).map((key, index) => (
                    <th key={index}>{key}</th>
                  ))} */}
                </tr>
              </thead>
              <tbody>
                {displayedData.length > 0 ? (
                  displayedData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td align="center">{rowIndex + 1}</td>
                      <td>{row.Contact_Name}</td>
                      <td>{row.Contact_Email_ID}</td>
                      <td>{row.Contact_Mob_No}</td>
                      <td>{row.Subject}</td>
                      <td>{row.Contact_Remarks}</td>
                      <td>                       
                        {new Date(row.Date_Time_Stamp)
                          .toLocaleDateString("de-DE", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })}
                        ,{" "}
                        {new Intl.DateTimeFormat("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        }).format(new Date(row.Date_Time_Stamp))}                        
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      align="center"
                      style={{ padding: "20px", fontWeight: "bold" }}
                    >
                      No contacts available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {totalPages > 1 && (
              <PaginationWidget
                totalPages={totalPages}
                onPageChanged={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
