import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";

export default function PageLayout({ children }) {
  // const sectionsRef = useRef([]);
  // let touchStartY = 0;

  // useEffect(() => {
  //   const sections = document.querySelectorAll(".section");
  //   sectionsRef.current = sections;
  //   const isHomepage = document.querySelector(".homepage");
  //   if (!isHomepage) return; // Only apply logic if `homepage` class exists

  //   let currentSectionIndex = 0;
  //   let isScrolling = false;
  //   const scrollToSection = (index) => {
  //     if (index >= 0 && index < sections.length) {
  //       isScrolling = true;
  //       sections[index].scrollIntoView({ behavior: "smooth" });
  //       setTimeout(() => (isScrolling = false), 100);
  //     }
  //   };

  //   const handleWheel = (event) => {
  //     if (isScrolling) return;

  //     if (event.deltaY > 0 && currentSectionIndex < sections.length - 1) {
  //       currentSectionIndex++;
  //     } else if (event.deltaY < 0 && currentSectionIndex > 0) {
  //       currentSectionIndex--;
  //     }
  //     scrollToSection(currentSectionIndex);
  //   };

  //   const handleTouchStart = (event) => {
  //     touchStartY = event.touches[0].clientY;
  //   };

  //   const handleTouchMove = (event) => {
  //     if (isScrolling) return;

  //     const touchEndY = event.touches[0].clientY;
  //     if (touchStartY - touchEndY > 50 && currentSectionIndex < sections.length - 1) {
  //       currentSectionIndex++;
  //       scrollToSection(currentSectionIndex);
  //     } else if (touchEndY - touchStartY > 50 && currentSectionIndex > 0) {
  //       currentSectionIndex--;
  //       scrollToSection(currentSectionIndex);
  //     }
  //   };

  //   window.addEventListener("wheel", handleWheel);
  //   window.addEventListener("touchstart", handleTouchStart);
  //   window.addEventListener("touchmove", handleTouchMove);

  //   return () => {
  //     window.removeEventListener("wheel", handleWheel);
  //     window.removeEventListener("touchstart", handleTouchStart);
  //     window.removeEventListener("touchmove", handleTouchMove);
  //   };
  // }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`smooth-scroll ${isScrolled ? "scrolled" : ""}`}>
      <Header />
      {children}
      <Footer />
    </div>
  );
}
