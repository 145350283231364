import React, { useEffect, useState } from "react";
import AdminHeader from "../../../../component/AdminComponent/AdminHeader";
import api from "../../../../services/api";
import PaginationWidget from "../../../../component/AdminComponent/PaginationWidget";
import IconSearch from "../../../../component/Icons/IconSearch";

const ENTRIES_PER_PAGE = 20;

export default function PreOrderList() {
  const [formattedData, setFormattedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const response = await api.post(
          "/list_preorder",
          {},
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (isMounted) {
          const reversedData = response.data.data.sort(
            (a, b) => new Date(b.Order_Date_Time) - new Date(a.Order_Date_Time)
          );          
          setFormattedData(reversedData);
          setFilteredData(reversedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  // ✅ Handle search
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredData(formattedData);
    } else {
      const lowercasedValue = value.toLowerCase();
      const filtered = formattedData.filter((item) =>
        Object.values(item).some(
          (field) =>
            field &&
            field.toString().toLowerCase().includes(lowercasedValue)
        )
      );
      setFilteredData(filtered);
    }
    setCurrentPage(1); // ✅ Reset to first page after searching
  };


  // Calculate total pages and displayed data based on current page
  const totalPages = Math.ceil(formattedData.length / ENTRIES_PER_PAGE);
   const displayedData = filteredData.slice(
    (currentPage - 1) * ENTRIES_PER_PAGE,
    currentPage * ENTRIES_PER_PAGE
  );


  const getStatusClass = (status) => {
    switch (status?.toLowerCase()) {
      case "rejected":
        return "text-red-500"; // Red for rejected
      case "pending":
        return "text-yellow-500"; // Yellow for pending
      case "accepted":
        return "text-green-500"; // Green for accepted
      default:
        return "text-white"; // Default gray for unknown status
    }
  };

  return (
    <section className="locationpage">
      <AdminHeader pageTitle="List of Preorders" />
      <div className="pageLeftspace">
        <div className="tableheader flex-column gap-4 d-flex justify-content-between align-items-center">
           <div className="search-bar w-full">
            <input type="text" className="form-control" placeholder="Search Contact Details..." value={searchTerm} onChange={handleSearch}/>
            <IconSearch/>
            </div>

          <div className="tablescroll">
            <table className="table table-bordered mb-3">
              <thead>
                <tr>                  
                  <th align="center" width="60">Order No.</th>
                  <th>Name</th>
                  <th>Customer Email</th>
                  <th>Mobile No.</th>
                  <th>Address</th>
                  <th>Message</th>
                  <th>Denial Reason</th>
                  <th>Order Date & Time</th>
                  <th>Status</th>                 
                </tr>
              </thead>
              <tbody>
              {displayedData.length > 0 ? (
                  displayedData.map((row, rowIndex) => (
                  <tr key={rowIndex}>                     
                    <td align="center">{row.Order_No}</td>
                    <td>{row.Cutomer_Name}</td>
                    <td>{row.Customer_Email_ID}</td>
                    <td>{row.Customer_Mob_No}</td>
                    <td title={row.Customer_Address}>
                      {row.Customer_Address?.split(" ").slice(0, 10).join(" ") + (row.Customer_Address?.split(" ").length > 10 ? "..." : "")}
                    </td>
                    <td title={row.Customer_Remarks}>                     
                      {row.Customer_Remarks?.split(" ").slice(0, 10).join(" ") + (row.Customer_Remarks?.split(" ").length > 10 ? "..." : "")}

                    </td>
                    <td title={row.rejectionReason}>
                    {row.rejectionReason 
                      ? row.rejectionReason.split(" ").slice(0, 10).join(" ") + (row.rejectionReason.split(" ").length > 10 ? "..." : "") 
                      : "not denied"}
                    </td>                  
                    <td>
                    {new Date(row.Order_Date_Time)
                          .toLocaleDateString("de-DE", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })}
                        ,{" "}
                        {new Intl.DateTimeFormat("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        }).format(new Date(row.Order_Date_Time))}

                    </td>                 
                  <td>
                   <div  className={`font-semibold ${getStatusClass(row.Order_Status)}`}> {row.Order_Status}</div>
                  </td>
                  </tr>
               ))
              ) : (
                <tr>
                  <td colSpan="9" align="center" style={{ padding: "20px", fontWeight: "bold" }}>
                    No orders available
                  </td>
                </tr>
              )}
              </tbody>
            </table>
            {totalPages > 1 && (
              <PaginationWidget
                totalPages={totalPages}
                onPageChanged={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
