import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconBar from "../../Icons/IconBar";
import IconSignIn from "../../Icons/IconSignIn";
import IconUser from "../../Icons/IconUser";
import Sidebar from "../SideBar";
import "./style.css";


export default function AdminHeader({pageTitle}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    if (!sidebarOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  };
  const handleLogout = () => {
    // Clear any authentication data (e.g., remove tokens from local storage)
    localStorage.removeItem("authToken"); // Example: removing a token    
    navigate("/admin");
       // Push a dummy state to the history stack
       window.history.pushState(null, "", window.location.href);

       // Listen for popstate events and push a dummy state
       window.onpopstate = () => {
         window.history.pushState(null, "", window.location.href);
       };
  };

  return (
    <section>
      <div className="header-top d-flex align-items-center justify-content-between">
        <div className="page-title d-flex align-items-center">
          <Link to="#" onClick={toggleSidebar}><IconBar /></Link>
          <h2>{pageTitle}</h2>
        </div>
        <div className="user">
        <button title="LogOut" className="logout" onClick={handleLogout}><IconSignIn/> LogOut</button>
          <Link to="#" className="usericon">
            <IconUser />
          </Link>          
        </div>
      </div>
      <Sidebar isOpen={sidebarOpen} />
    </section>
  );
}
