import React from "react";

export default function IconCalendar() {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m39 6h-3c0-1.654-1.346-3-3-3s-3 1.346-3 3h-12c0-1.654-1.346-3-3-3s-3 1.346-3 3h-3c-2.757 0-5 2.243-5 5v29c0 2.757 2.243 5 5 5h30c2.757 0 5-2.243 5-5v-29c0-2.757-2.243-5-5-5zm-7 0c0-.551.448-1 1-1s1 .449 1 1v2c0 .551-.448 1-1 1s-1-.449-1-1zm-18 0c0-.551.448-1 1-1s1 .449 1 1v2c0 .551-.448 1-1 1s-1-.449-1-1zm-8 5c0-1.654 1.346-3 3-3h3c0 1.654 1.346 3 3 3s3-1.346 3-3h12c0 1.654 1.346 3 3 3s3-1.346 3-3h3c1.654 0 3 1.346 3 3v4h-36zm36 29c0 1.654-1.346 3-3 3h-30c-1.654 0-3-1.346-3-3v-23h36z"></path>
      <path d="m12 28h3c1.103 0 2-.897 2-2v-3c0-1.103-.897-2-2-2h-3c-1.103 0-2 .897-2 2v3c0 1.103.897 2 2 2zm0-5h3l.002 3h-3.002z"></path>
      <path d="m22.5 28h3c1.103 0 2-.897 2-2v-3c0-1.103-.897-2-2-2h-3c-1.103 0-2 .897-2 2v3c0 1.103.897 2 2 2zm0-5h3l.002 3h-3.002z"></path>
      <path d="m33 28h3c1.103 0 2-.897 2-2v-3c0-1.103-.897-2-2-2h-3c-1.103 0-2 .897-2 2v3c0 1.103.897 2 2 2zm0-5h3l.002 3h-3.002z"></path>
      <path d="m12 39h3c1.103 0 2-.897 2-2v-3c0-1.103-.897-2-2-2h-3c-1.103 0-2 .897-2 2v3c0 1.103.897 2 2 2zm0-5h3l.002 3h-3.002z"></path>
      <path d="m22.5 39h3c1.103 0 2-.897 2-2v-3c0-1.103-.897-2-2-2h-3c-1.103 0-2 .897-2 2v3c0 1.103.897 2 2 2zm0-5h3l.002 3h-3.002z"></path>
      <path d="m33 39h3c1.103 0 2-.897 2-2v-3c0-1.103-.897-2-2-2h-3c-1.103 0-2 .897-2 2v3c0 1.103.897 2 2 2zm0-5h3l.002 3h-3.002z"></path>
    </svg>
  );
}
