import React from "react";

export default function IconPhone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="24"
      height="24"
      x="0"
      y="0"
      viewBox="0 0 512 512"      
    >
      <g>
        <path
          d="m397.836 302.73-.242-.246a3.6 3.6 0 0 0-.121-.117L368.8 273.695c-8.059-8.062-18.774-12.5-30.172-12.5-11.328 0-21.988 4.387-30.031 12.356-.059.054-.114.113-.172.168l-15.239 15.242-70.175-70.18 15.234-15.238c.059-.055.113-.113.168-.168 16.496-16.648 16.453-43.613-.14-60.207l-28.63-28.633-.156-.156c-8.058-8.043-18.765-12.473-30.148-12.473-11.348 0-22.02 4.403-30.067 12.39-.109.11-.218.22-.324.333l-13.148 13.144a20267.907 20267.907 0 0 0-12.434 12.438c-20.305 20.3-25.05 52.664-13.363 91.129 10.703 35.23 34.254 72.289 66.308 104.348 44.75 44.75 99.555 72.546 143.02 72.546 21.496 0 39.64-6.777 52.398-19.539.266-.261 7.606-7.601 16.32-16.316l9.544-9.543.05-.05c16.395-16.45 16.551-43.126.446-59.774a6.094 6.094 0 0 0-.254-.282zm-21.348 38.782c-.09.09-.18.18-.27.273l-9.378 9.38c-8.567 8.565-15.8 15.8-16.266 16.26-7.172 7.173-17.683 10.81-31.242 10.81-35.262 0-83.07-25.028-121.805-63.758-28.636-28.637-49.523-61.258-58.82-91.86-8.195-26.965-6.055-49.27 5.883-61.207l12.422-12.422 13.355-13.355c.051-.051.098-.098.145-.149a12.592 12.592 0 0 1 8.828-3.578 12.603 12.603 0 0 1 8.976 3.727l28.743 28.746c4.941 4.941 4.941 12.98 0 17.922-.008.008-.016.02-.028.027v.004l-25.844 25.844c-5.855 5.86-5.855 15.355 0 21.215l91.395 91.39a14.986 14.986 0 0 0 10.605 4.395c3.981 0 7.797-1.582 10.61-4.395l25.68-25.687c.093-.086.183-.18.273-.27a12.605 12.605 0 0 1 8.879-3.629c3.383 0 6.562 1.32 8.957 3.711l28.668 28.664.062.067c.032.031.063.062.09.093 4.875 4.903 4.899 12.844.082 17.782zm0 0"
          fill="#ffffff"
          opacity="1"
          dataOriginal="#000000"
        ></path>
        <path
          d="M437.02 74.98C388.668 26.63 324.383 0 256 0S123.332 26.629 74.98 74.98C26.63 123.336 0 187.621 0 256s26.629 132.668 74.98 181.02C123.332 485.37 187.621 512 256 512s132.664-26.629 181.02-74.98C485.37 388.668 512 324.383 512 256c0-68.379-26.629-132.664-74.98-181.02zM256 482C131.383 482 30 380.613 30 256S131.383 30.004 256 30c124.617.004 226 101.387 226 226-.004 124.617-101.387 226-226 226zm0 0"
          fill="#ffffff"
          opacity="1"
          dataOriginal="#000000"
        ></path>
      </g>
    </svg>
  );
}
