import React, { useEffect, useState } from "react";
import { BentoGrid, BentoGridItem } from "./BentoGrid";
import PageLayout from "../../component/PageLayout";
import api from "../../services/api";
import { motion, AnimatePresence } from "framer-motion";
import { X, ChevronLeft, ChevronRight } from "lucide-react";

export function Gallery() {
  const [galleryPic, setGalleryPic] = useState([]);
  const [imageLoading, setImageLoading] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await api.post(
          "/List_Image_Gallery",
          {},
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.data && response.data.success) {
          const datarevarse = response.data.data.reverse()          
          setGalleryPic(datarevarse);
          setImageLoading(
            response.data.data.reduce(
              (acc, _, index) => ({ ...acc, [index]: true }),
              {}
            )
          );
        }
      } catch (error) {
        console.error("Error fetching gallery images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const handleImageLoad = (index) => {
    setImageLoading((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  const openModal = (index) => {
    // console.log("Opening modal for index:", index);
    setSelectedImage(index);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const nextImage = () => {
    setSelectedImage((prevIndex) =>
      prevIndex !== null && prevIndex < galleryPic.length - 1
        ? prevIndex + 1
        : prevIndex
    );
  };

  const prevImage = () => {
    setSelectedImage((prevIndex) =>
      prevIndex !== null && prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  return (
    <PageLayout>
      <section className="section gallerypagemain ptinner">
        <div className="titleone text-center">
          <h4>Our Gallery</h4>
        </div>
        <div className="container">
          {loading ? (
            <div className="bookform-left text-center py-32 mt-14">
              Loading Gallery Pictures...
            </div>
          ) : galleryPic.length === 0 ? (
            <div className="bookform-left text-center py-32 mt-14">
              No Gallery Pictures available
            </div>
          ) : (
            <BentoGrid className="mx-auto">
              {galleryPic.map((item, i) => (
                <BentoGridItem
                  key={i}
                  title={item.Image_Title}
                  img={`${process.env.REACT_APP_API_PRJ_URL}${item.Image_Path}`}
                  className={
                    i === 3 || i === 6 ? "md:col-span-1 lg:col-span-2" : ""
                  }
                  imageLoading={imageLoading[i]}
                  handleImageLoad={() => handleImageLoad(i)}
                  index={i}
                  onClick={() => openModal(i)} // Open modal on click
                />
              ))}
            </BentoGrid>
          )}
        </div>
      </section>

      {/* Animated Modal */}
      <AnimatePresence>
        {selectedImage !== null && galleryPic[selectedImage] && (
          <motion.div
            className="fixed inset-0 bg-gray-900 bg-opacity-90 flex items-center justify-center z-[99]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="relative bg-white rounded-lg  p-4 shadow-lg max-w-3xl w-full"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
            >
              <button
                onClick={closeModal}
                className="modalarrow top-5 right-5 absolute text-gray-950 hover:text-black"
              >
                <X size={24} />
              </button>

              <div className="flex items-center justify-between">
                <button
                  onClick={prevImage}
                  disabled={selectedImage === 0}
                  className="text-gray-600 hover:text-black modalarrow modalleft"
                >
                  <ChevronLeft size={32} />
                </button>

                <div className="flex flex-col items-center">
                  <img
                    src={`${process.env.REACT_APP_API_PRJ_URL}${galleryPic[selectedImage].Image_Path}`}
                    alt={galleryPic[selectedImage].Image_Title}
                    className="max-h-[500px] w-auto rounded-lg"
                  />
                  <p className="mt-2 text-lg text-black font-semibold">
                    {galleryPic[selectedImage].Image_Title}
                  </p>
                </div>

                <button
                  onClick={nextImage}
                  disabled={selectedImage === galleryPic.length - 1}
                  className="text-gray-600 hover:text-black modalarrow modalright"
                >
                  <ChevronRight size={32} />
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </PageLayout>
  );
}
