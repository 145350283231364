import React, { useEffect, useState } from "react";
import PageLayout from "../../component/PageLayout";
import LeafIcon from "../../component/Icons/LeafIcon";
import RoundCircalIcon from "../../component/Icons/RoundCircalIcon";
import "./style.css";
import api from "../../services/api";

export default function FoodItems() {
  const [menuItems, setMenuItems] = useState([]);
  const [dishes, setDishes] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [categoriesResponse, dishesResponse] = await Promise.all([
        api.post("/list_RegularCategories", {}, { headers: { "Content-Type": "application/json" } }),
        api.post("/list_regular_dishes", {}, { headers: { "Content-Type": "application/json" } })
      ]);      
      
      setMenuItems(categoriesResponse.data.data || []);
      setDishes(dishesResponse.data.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally {
      setLoading(false); 
    }
  };

  // Grouping dishes under their respective categories
  const groupedMenuData = menuItems.map((category) => ({
    ...category,
    dishes: dishes.filter((dish) => dish.Category === category.Category && dish.status === true),
  }));

  // Split the categories into two columns
  const midIndex = Math.ceil(groupedMenuData.length / 3);
  const firstColumn = groupedMenuData.slice(0, midIndex);
  const secondColumn = groupedMenuData.slice(midIndex);
  
  return (
    <PageLayout>
      <section className="section food-menu-main ptinner">
        <div className="titleone text-center">
          <h4>Food Items Menu</h4>
        </div>

        <div className="container">
        {loading ? ( 
            <div className="bookform-left text-center py-32 mt-14">Menu Data Loading...</div>
          ) : groupedMenuData.length === 0 ? (
            <div className="bookform-left text-center py-32 mt-14">No Menu Data Found</div>
          ) : (
          <div className="menu-main">
          <div className="menubar menubar02">
          {firstColumn.map((category, index) => (
          <div className="menubox" key={index}>
                  <div className="maintitle">
                  <h5>{category.Category}</h5> 
                  <span>{category.DishQuality}</span>
                </div>
                {category.dishes.map((dish, dishIndex) => (
                  <div className="menucategory">
                    <div className="itemname">
                    <div className={`categaryname ${dish.ChefsSpecial ? 'favrate' : ''} ${dish.Addons ? 'categaryname02' : ''}`}>
                      <h6>{dish.Dish_Name}</h6>{!dish.Addons && <sup>{dish.Allergydetails}</sup>}

                      {dish.Addons && (
                        <label>
                          ({dish.Addons}) <sup>{dish.Allergydetails}</sup>
                        </label>
                      )}
                    </div>
                      <figcaption>
                      {dish.Vegan && <LeafIcon />}
                      {dish.Gluten_free && <RoundCircalIcon />}
                      {dish.Price && <span>€{dish.Price}</span>} 
                      </figcaption>
                    </div>
                    {dish.Description && <p>{dish.Description}</p>}  
                    </div>
                  ))}
                  </div>
              
                ))}
              </div>

              {/** Column 2 */}
              <div className="menubar">
              {secondColumn.map((category, index) => (
              <div className="menubox" key={index}>
                  <div className="maintitle">
                  <h5>{category.Category}</h5> 
                  <span>{category.DishQuality}</span>
                </div>
                {category.dishes.map((dish, dishIndex) => (
                  <div className="menucategory">
                    <div className="itemname">
                    <div className={`categaryname ${dish.ChefsSpecial ? 'favrate' : ''} ${dish.Addons ? 'categaryname02' : ''}`}>
                      <h6>
                        {dish.Dish_Name} 
                      </h6>{!dish.Addons && <sup>{dish.Allergydetails}</sup>}
                      
                      {dish.Addons && (
                        <label>
                          ({dish.Addons}) <sup>{dish.Allergydetails}</sup>
                        </label>
                      )}
                    </div>
                      <figcaption>
                      {dish.Vegan && <LeafIcon />}
                      {dish.Gluten_free && <RoundCircalIcon />}
                      {dish.Price && <span>€{dish.Price}</span>} 
                      </figcaption>
                    </div>
                    {dish.Description && <p>{dish.Description}</p>}                         
                    </div>
                  ))}              
                   </div>
                ))}             
              </div>

          </div>
          )}
        </div>
      </section>
    </PageLayout>
  );
}
